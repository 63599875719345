// @flow
import {useTranslation} from 'gatsby-plugin-react-i18next';
import React, {useEffect} from 'react';

import HeaderSection from '_components/HeaderSection';
import Layout from '_components/Layout';
import RTMContestRules from '_components/RTMContestRules';
import useAnalytics, {event} from '_hooks/useAnalytics';

const Page = (): React$Node => {
  const {t} = useTranslation();
  const {track} = useAnalytics();

  useEffect(() => {
    track({
      group: event.group.RTMContestRules,
      action: event.action.Viewed,
    });
  }, [track]);

  return (
    <Layout floatingNavbar={false} inverseFooter>
      <HeaderSection title="RightThisMinute x Ritual FIT “Get FIT” Giveaway" backgroundColor="#160f10" />
      <RTMContestRules />
    </Layout>
  );
};

export default Page;
